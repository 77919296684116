import type { AppProps } from 'next/app';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import '../styles/globals.css';
import { DefaultSeo } from 'next-seo';
import { useEffect, useState } from 'react'

function MyApp({ Component, pageProps }: AppProps) {
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);


  return (
    <>
      <DefaultSeo
        title="Flint Online Meetings"
        titleTemplate="%s"
        defaultTitle="Flint Online Meetings"
        description="Flints online meeting platform. Find out more at flint.now"
        twitter={{
          handle: '@livekitted',
          site: '@livekitted',
          cardType: 'summary_large_image',
        }}
        openGraph={{
          url: 'https://meet.flint.now',
          images: [
            {
              url: 'https://meet.flint.now/images/open-graph.png',
              width: 2000,
              height: 1000,
              type: 'image/png',
            },
          ],
          site_name: 'Flint Online Meetings',
        }}
        additionalMetaTags={[
          {
            property: 'theme-color',
            content: '#070707',
          },
        ]}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.ico',
          },
          {
            rel: 'apple-touch-icon',
            href: '/images/apple-touch-icon.png',
            sizes: '180x180',
          },
          {
            rel: 'mask-icon',
            href: '/images/safari-pinned-tab.svg',
            color: '#070707',
          },
        ]}
      />
      {domLoaded && (
      <Component {...pageProps} suppressHydrationWarning />
      )}
    </>
  );
}

export default MyApp;
